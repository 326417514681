import React from 'react'

const Transparant = ({header ,text1 , text2}) => {
  return (
    <div className='transparant' style={{textAlign: 'center', fontWeight:"bold" , fontSize:"1.1rem" , color:"#002c41", backgroundColor:'#f4fef9',padding: '33px 70px',width:'100%'}}>
        <h4>{header}</h4>
        <div className='trs'>
            <p>{text1}</p>
            <p>{text2}</p>
        </div>
    </div>
  )
}

export default Transparant
