import React  from 'react'
import { Link } from 'react-router-dom'

const Regulier = ({title , title2, text1, text2 , link, idd, img}) => {

  return (
    <div className='Regulier-sect'>
        <div className='regu'>
        {idd ? 
        <div id={idd}>
          <h4>{title}</h4>
          {title2 && <h5 style={{  marginBottom: '0.75rem',color: '#002c41',fontSize:'15px'}}>{title2}</h5>}
          <p className='para'>{text1}</p>
          {text2 && <p className='para'>{text2}</p>}
        </div> : 
        <div>
          <h4>{title}</h4>
          <p className='para'>{text1}</p>
          {text2 && <p className='para'>{text2}</p>}
        </div>}
          {/* <h4>{title}</h4>
          <p className='para'>{text1}</p> */}
          {link && <Link to={"/Sportstimulering"} className='learn-more-container' >
            <div className='d-flex justify-start'>
                <i className="fa-solid fa-circle-plus fa-2x color-primary"></i>              
                <p className='learn-more'>Lees meer</p>
            </div>
          </Link>}
        </div>
        {img ? <img className='regulier-img' src={"images/"+img} alt='Regulier'/> : <img className='regulier-img' src="images/regulier.jpg" alt='Regulier'/>}
    </div>
  )
}

export default Regulier
